import React from 'react'
import { Card } from 'react-bootstrap'
import { bordMembers } from '../resources/boardMembers'
import president from '../images/MDS.jpg'

// const src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYU___dx8hmsm4i_BCg8flJoo2NKHo_Z0h1Q&usqp=CAU"
function BoardMembers() {
  return (
    <div className="paddingStyle">


    <section >
      <div className='boardMemberContainer' style={{textAlign:'center' }}>
        <h2 style={{fontSize:'33px'}}>Board Members</h2>
        <br />
        <br />

        <Card style={{ width: '100%',textAlign:'center' }}>
          <Card.Body>
            <img src={president} alt="" style={{borderRadius:'50%',width:'270px'}} />
            <Card.Title><h2> {bordMembers[0].name} </h2> <span>{bordMembers[0].title}</span></Card.Title>

          </Card.Body>
        </Card>
        <div className='bordMember'>
        <Card style={{ width: '100%',textAlign:'center' }}>
          <Card.Body>
            <Card.Title><h2> {bordMembers[1].name} </h2> <span>{bordMembers[1].title}</span></Card.Title>

          </Card.Body>
        </Card>
        <Card style={{ width: '100%',textAlign:'center' }}>
          <Card.Body>
            <Card.Title><h2> {bordMembers[2].name} </h2> <span>{bordMembers[2].title}</span></Card.Title>

          </Card.Body>
        </Card>
        <Card style={{ width: '100%',textAlign:'center' }}>
          <Card.Body>
            <Card.Title><h2> {bordMembers[3].name} </h2> <span>{bordMembers[3].title}</span></Card.Title>

          </Card.Body>
        </Card>
        <Card style={{ width: '100%',textAlign:'center' }}>
          <Card.Body>
            <Card.Title><h2> {bordMembers[4].name} </h2> <span>{bordMembers[4].title}</span></Card.Title>

          </Card.Body>
        </Card>

        </div>
        
      </div>
    </section>
    </div>
  )
}

export default BoardMembers 