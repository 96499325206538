import React from "react";
import logo from "../images/cure2.jpg";

function AboutUs() {
  return (
    <>
    <div className="paddingStyle">
      <section className="aboutus">
        {/* <h1 style={{textAlign:'center',fontSize:'33px'}}>About Us:</h1> */}
        <div
          className="about-content"
          style={{ display: "flex", gap: "150px" }}
        >
          <div style={{alignSelf:'center'}}>
            <img src={logo} alt="" style={{width:'30vw',height:'60vh'}}  />
          </div>
          <div>
            <p style={{}}>
              Charity for Urban and Rural Enhancement (CURE) is a not-for-profit
              organisation founded in the year 2007, under the visionary
              leadership of Rev. R. John Elangovan (Late). Headquarted in
              Mysore, India, we strive to improve the Social, Economic and Moral
              standards of both Rural & Urban community.
            </p>
            <p>
              CURE is committed to the transformation of the underprivileged
              children, youth and women in Urban and Rural areas. We work among
              different people groups irrespective of their religion, language
              and beliefs.
            </p>
            <p>
              Since its inception, CURE has been reaching out to various sectors
              like Education, Family, Health Services, Community Development,
              Slum Development, Environmental Awareness, Relief and support,
              Women & Child Development, Youth Development etc.
            </p>
            <p>
              At present CURE is active in Karnataka with several social
              development activities like educational support, rehabilitation of
              youth, medical support, relief support in emergencies, feeding the
              poor, widows care, HIV patients care, Referral services etc.
            </p>
            <p>
              CURE is registered under Societies Registration Act in Karnataka.
            </p>
          </div>
        </div>
      </section>
      </div>
    </>
  );
}

export default AboutUs;
