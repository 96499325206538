import React from 'react'
import { Carousel } from 'react-bootstrap'
import testimonial1 from '../images/testimonial1.jpg'
const testimonials = [
  {
    img: testimonial1,
    para: "I come from a poor family in Gulburga. I had severe abdomen pain due to Polycystic ovarian Disease and my stomach had a swelling just like 9 month pregnant lady. Several doctors in my village suggested to go to City for treatment for which I couldn't afford. Meanwhile my health condition worsened. My relatives thought that I will die soon. At this critical time in my life, CURE representative in Gulburga came to know about my condition and informed their Head Office. The CURE team helped in my hospital procedures such as recommending my case to doctors in Bangalore, advocating for fee concession. I went through the surgery and totally recovered now. The team's encouragement and emotional support during my worst time was commendable. I never thought I would be normal again. But now I am healthy and happy. I thank God and the CURE team who helped me in my hopeless situation. Now I am moving forward with hope.",
    name: 'Jhansi'
  }
];



function Testimonials() {
  return (
    <section style={{'textAlign':'center','padding':'20px'}}>
      <div className='testimonialContainer'>

        <Carousel>
          {
            testimonials.map(({ img, para, name }) => {
              return (
                <Carousel.Item>
                  <img src={img} alt="" /><br /><br /><br />
                  <p>{para}</p>
                  <h3 style={{color:'black'}}>{name}</h3>
                </Carousel.Item>
              )
            })
          }
        </Carousel>
      </div>
    </section>
  )
}

export default Testimonials