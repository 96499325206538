import React, { useEffect, useState } from "react";
import "../style.scss";
import $ from 'jquery';
import logo from "../images/Logo.png";
import { Link } from "react-router-dom";
import { MdEmail, MdPhone } from "react-icons/md"
import { useNavigate } from 'react-router-dom'


// import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

function Nav1() {
  let navigate = useNavigate()

  let backgroundColor = 'white'
  function myFunction(e) {
    let x = document.getElementById("ham");
    console.log(x);
    x.classList.toggle("change");
    var y = document.getElementById("myLinks");
    console.log(y);
    console.log(y.style.display);
    if (y.style.display === "flex") {
      y.style.display = "none";
    } else {
      y.style.display = "flex";
    }
    let id=e.target.id
    // console.log(id)
    let nav=id.split('-')[0]
    console.log(id.split('-'))
    navigate(`/${nav}`)
    myFunctionX(id,1)
  }

  function myFunctionX(e,flag=0) {
    let ids=['about', 'founder', 'bordMembers' ]
    let id=e
    if (flag==0){
      id = e.target.id
    }
    console.log(id)
    // document.getElementById(id).style.backgroundColor='gray'
    $('.link').css('background-color', 'white')
    $('.link').css('color', 'black')
    
    $('.link1').css('background-color', 'gray')
    $('.link1').css('color', 'black')
  
    $(`#${id}`).css('background-color', '#ED8634')
    $(`#${id}`).css('color', 'white')

    $(`#${id}-1`).css('background-color', '#ED8634')
    $(`#${id}-1`).css('color', 'white')
    if(ids.includes(id)){
      $('#abtBtn').css({'background-color':'#ED8634','color':'white'})
    }

  }
  useEffect(() => {
    var dropdown = document.getElementsByClassName("dropdown-btn");

    var i;
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  }, []);

  return (
    <>
      <nav className="navbar">
        <section style={{ display: 'flex', 'alignItems': 'center' }}>
          <Link to="/" style={{ padding: '0px' }}>
            <img id="brandImg" onClick={(e) => myFunctionX(e)} src={logo} className="brandLogo" alt="logo" />
          </Link>
          {/* <span className="logoAbbr">
              <span>Charity for Urban and </span><br />
              <span>Rural Enhancement </span>
            </span> */}
        </section>
        <section className="ham" id="ham" onClick={(e) => myFunction('ham')}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </section>
        <section className="items">
          <div className="dropdown">
            <button className="dropbtn link" id="abtBtn" >About us</button>
            <div className="dropdown-content">
              <Link to="/about" id="about" className="link" onClick={(e) => myFunctionX(e)}>about us</Link>
              <Link to="/founder" id="founder" className="link" onClick={(e) => myFunctionX(e)}>Founder</Link>
              <Link to="/bordMembers" id="bordMembers" className="link" onClick={(e) => myFunctionX(e)}>board Members</Link>
            </div>
          </div>
          <div>
            <Link to="/activities" id="activities" className="link" onClick={(e) => myFunctionX(e)}>activities</Link>
          </div>
          <div>
            <Link to="/testimonials" id="testimonials" className="link" onClick={(e) => myFunctionX(e)}>testimonials </Link>
          </div>
          <div>
            <Link to="/gallery" id="gallery" className="link" onClick={(e) => myFunctionX(e)}>gallery</Link>
          </div>
          <div>
            <Link to="/makeDifference" id="makeDifference" className="link" onClick={(e) => myFunctionX(e)}>Make a difference</Link>
          </div>
          <div>
            <Link to="/contact" id="contact" className="link" onClick={(e) => myFunctionX(e)}>contact</Link>
          </div>
        </section>
      </nav>

      <section className="items2" id="myLinks">
        <div className="sidenav">
          <button className="dropdown-btn"  >
            About us
            <i className="fa fa-caret-down"></i>
          </button>
          <div className="dropdown-container">

            <Link to="/about" id="about-1" className="link1" onClick={(e) => myFunction(e)}>about us</Link>
            <Link to="/founder" id="founder-1" className="link1" onClick={(e) => myFunction(e)}>Founder</Link>
            <Link to="/bordMembers" id="bordMembers-1" className="link1" onClick={(e) => myFunction(e)}>board Members</Link>

          </div>
        </div>
        <div id="activities-1" className="link1" onClick={(e)=>myFunction(e)}>
          activities
        </div>
        <div id="testimonials-1" className="link1" onClick={(e)=>myFunction(e)}>
          testimonials
        </div>
        <div id="gallery-1" className="link1" onClick={(e)=>myFunction(e)}>
          gallery
        </div>
        <div id="makeDifference-1" className="link1" onClick={(e)=>myFunction(e)}>
          Make a difference
        </div>
        <div id="contact-1" className="link1" onClick={(e)=>myFunction(e)}>
          contact
        </div>
      </section>

    </>
  );
}

export default Nav1;
