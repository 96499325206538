import React from 'react'
import phone from '../images/contact/phone-solid.svg'
import email from '../images/contact/envelope-solid.svg'
import loc from '../images/contact/location-dot-solid.svg'
function Contact() {
    return (
        <div className="paddingStyle">


            <div className='contacts ' style={{ paddingTop: '15px' }}>
                <div>
                    <p>
                        <br />
                        <br />
                        <br />
                        <h3 style={{ color: '#f08519' }}>Your donation is eligible for tax exemption.</h3>
                        <br />
                        Financial Details: <br />
                        PAN: AABTC4114G <br />
                        80G number: AABTC4114GF20212<br />
                        Dated: 19-11-2021<br />
                    </p>
                </div>
                <div>
                    <h1>Get Connected</h1>
                    <br />
                    <h3>Registered Office</h3>
                    <address style={{ display: 'flex' }}>
                        <img src={loc} style={{ alignSelf: 'baseline', marginTop: '6px' }} className='icon' alt="" />
                        <p>
                            Charity for Urban and Rural Enhancement (CURE). <br />
                            ICRM Campus, Mahadevapura,<br />
                            Mysore 570008<br />
                            <br />
                            <img src={phone} className='icon' />  Phone : &nbsp;&nbsp;<span>+91 9845559119</span><br />
                            <img src={email} className='icon' /> Email : &nbsp;&nbsp; <span>headoffice@cure-india.org</span>
                        </p>

                    </address>
                </div>

                <div>
                    <h3 style={{ color: '#f08519' }}>Our bank details</h3>
                    <div className='accInfo'>
                        Name : CURE <br />
                        A/c No : 17162010012241 <br />
                        A/c Type : Savings Bank<br />
                        IFSC Code : CNRB0011716<br />
                        Bank : Canara Bank<br />
                        Branch : J P Nagar, Mysore<br />
                    </div>
                </div>
                <div>
                    <h3>Admin Office</h3>
                    <address style={{ display: 'flex' }}>
                        <img src={loc} style={{ alignSelf: 'baseline', marginTop: '6px' }} className='icon' alt="" />
                        <p>
                            Charity for Urban and Rural Enhancement (CURE).<br />
                            #1/2, "C" Blocak, Janatha Colony,<br />
                            2nd Cross, Halleguddadaha Halli,<br />
                            Mysore Road, Bangalore 560026<br />
                            Karnataka, India
                            <br />
                            <br />
                            <img src={phone} className='icon' />  Phone : &nbsp;&nbsp;<span>+91 8553355463</span><br />
                            <img src={email} className='icon' /> Email : &nbsp;&nbsp; <span>headoffice@cure-india.org</span>
                        </p>
                    </address>


                </div>

            </div>
        </div>
    )
}

export default Contact