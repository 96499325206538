// import gal1 from '../images/gallery/gal1.jpg' 
// import gal2 from '../images/gallery/gal2.jpg'
// import camp from '../images/gallery/gal3.jpg'
// // import footBall from '../images/gallery/gal4.jpg'
// import study from '../images/gallery/gal5.jpg'
// import footBall from '../images/gallery/gal6.JPG'
// import medical from '../images/gallery/gal7.JPG'
// import gal8 from '../images/gallery/gal8.JPG'
// import gal9 from '../images/gallery/gal9.JPG'
// import gal10 from '../images/gallery/gal10.JPG'
import footBall from '../images/gallery/football coaching.jpg'
import study from '../images/gallery/study centre.jpg'
import camp from '../images/gallery/summer camp.jpg'
import dropIn from '../images/gallery/drop-in centre.jpg'

const activities = [{
    title: 'Football coaching',
    content: 'In 2009, football coaching for youth was started in the Haleguddada Halli slum to stop their involvement in gangs and petty crimes.  The football coaching happens 6 days in a week for 3 hours in the morning by an expert player for senior & junior boys.',
    img: footBall,
    loc: 'Bangalore'
}, {
    title: 'Study Centre',
    content: 'Study centre was started with an objective to provide study atmosphere for the school going students.  The students from 4th Std to II PUC come and study for one hour every day for 6 days in a week.',
    img: study,
    loc: 'Bangalore, Gulburga & Bidar'
},
{
    title: 'Drop-in Centre',
    content: 'Drop-in Centre is a hang around place for the youth in the slum.  The youth drop in to the centre whenever they are free and this has minimized their contact with other outside groups.  This centre has been used to gather the youths and conduct personality development classes & counselling.',
    img: dropIn,
    loc: 'Bangalore'
},
{
    title: 'Summer Camps',
    content: 'Since the kids in the slum area don’t have any other entertainment other than playing on the streets, the summer camps are conducted in their areas.  The objective is to help the kids to spend their summer vacation usefully by engaging them in art, craft and creative learning.  The summer camps create an atmosphere for the kids to learn values of life with fun',
    img: camp,
    loc: 'Bangalore & Gulburga'
},

]

const otherActivities = [
    'Youth Rehabilitation',
    'Awareness camps on social issues',
    'Life skill sessions for adolescents',
    'Capacity building training for volunteers',
    'Income generation training for women',
    'Education support - School fee, monthly support etc.',
    'Widow Care',
    'Medical support – Doctor fee, purchase of medicine etc.',
    'Medical Camps',
    'Referrals for de-addiction, job placements, school placements, medical treatments etc.',

]


const individuals = [
    {skill:'volunteer',text:'your time and resources/skills'},
    {skill:'share your talents',text:'Health professionals, Counsellors, Legal, Management, Teachers, Content writers, social media influencers, etc '},
    {skill:'visit',text:'our field areas and interact with community people to motivate them.'},
    {skill:'sponsor',text:'A child, a project, plan outings or meals for slum childrens'},
    {skill:'internship',text:'For students who want to gain short term social work experience or do projects with us.'},
    {skill:'fund raising',text:'Hosting & Planning'},
    
]

const corporates=[
   'School fee',
'Note books',
'School Bags',
'Funeral expenses',
'Widow support',
'Medical Bills',
'Medical camps',
'Summer camps',
'Slum Development',

]

const corporates1=[
   ' Remunerations to teachers',
'Remunerations to Football Coaches',
'Rent for Tuition centres'

]

export { activities, otherActivities ,individuals,corporates,corporates1}