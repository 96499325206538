import React from 'react'
// import loc from '../images/contact/location-dot-solid.svg'
import phone from '../images/contact/phone-solid.svg'


function Footer() {
    return (
        <section className='footerContainer'>
            <address>
                        Charity for Urban and Rural Enhancement (CURE). <br />
                        ICRM Campus, Mahadevapura,
                         Mysore 570008<br />
                       Mobile:-  <span>+91 9845559119</span>&nbsp;&nbsp;&nbsp;&nbsp;

            </address>


        </section>
    )
}

export default Footer