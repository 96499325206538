import React from 'react'

function Involve() {
    return (
        <section style={{textAlign:'center','padding':'20px'}}>
            <h3>Get Involved</h3>
            <div className='involveContainer'>
            <p>We accept volunteers from all walks of life and we are always looking for fresh ideas.
                Volunteers can choose how to assist us based on our needs and their skills
                GET INVOLVED....together we can make a difference
            </p>
            </div>
        </section>
    )
}

export default Involve