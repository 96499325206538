import React from 'react'
import founder from '../images/founder.jpg'

function Founder() {
    return (
    <div className="paddingStyle">


        <div className='founderContainer '>
            <h3 style={{fontSize:'33px'}}>About the Founder</h3>
            <br />
            <div className="foundrContent">
                <div>
                    <p>
                        Founder of CURE, Rev. R. John Elangovan was an Apostle & an International Preacher.

                        He had a passion for the underprivileged people and would do whatever possible when someone in need approaches him.
                    </p>
                    <p>
                        In his 30th year of ministerial journey, Rev. R. John Elangovan was inspired by The Lord to do something for the underprivileged in the society.
                    </p>
                    <p>
                        It was in 1997, that the idea of CURE, an NGO took shape with an objective to transform the lives of underprivileged children, youth and women in Urban and Rural areas.
                    </p>
                    <p>
                        Apart from the passion, commitment, leadership qualities and awesome personality, he was a very simple human being and was easily approachable.  People working with him could discuss new ideas including their personal problems which he would listen to with utmost interest at any time of the day.
                    </p>
                    <p>
                        Though he is not physically present with us today, his ideologies and his vision are with us, which gives us immense strength to carry on the flame of commitment started by Rev. R. John Elangovan.
                    </p>
                </div>
                <div>
                    <img src={founder} style={{boxShadow:'2px 7px  20px rgb(65, 63, 63)'}} alt="" />
                </div>
            </div>
        </div>
    </div>
    )
}

export default Founder