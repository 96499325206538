import React, { useState } from 'react'
import { gallery } from '../images/gallery/gallery'
import { Carousel, Modal } from 'react-bootstrap';

function Gallery() {
    const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    console.log(show)
    const [imgSrc, setImgSrc] = useState(null)
    const imgClickHandler = (src) => {
        console.log(document.documentElement.scrollHeight)
        console.log(src)
        // onClick={handleShow}
        setImgSrc(src)
        handleShow()
    }
    return (
        
        <div className='paddingStyle'>

            <section style={{ textAlign: 'center', padding: '20px' }} >
                <div className='galleryContainer'>
                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-30w"
                    >
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <img src={imgSrc} style={{ width: '100%', maxHeight: '80vh' }} alt="" srcset="" />

                    </Modal>
                    {/* {show?<div className='imgDispContainer'>
                    <div>
                        <button className='btn btn-secondary' onClick={()=>setShow(false)} style={{float:'right'}}>x</button>
                        <br />
                        <img src={imgSrc} style={{width:'95%'}} alt="" srcset="" />
                    </div>
                    
                </div>:null} */}

                    <div className='imgContainer'>
                        {gallery.map((img) => {
                            return (
                                <img src={img} onClick={() => imgClickHandler(img)} alt="" srcset="" />
                            )
                        })}
                        {/* <Carousel data-interval="false">

                        {
                            gallery.map((arr) => {
                                return (
                                    <Carousel.Item>
                                        <div style={{display:'flex',gap: '20px'}}>

                                            {arr.map((img) => {
                                                return (

                                                    <img src={img} onClick={() => {
                                                        setImgSrc(img)
                                                    }} />
                                                )
                                            })}
                                        </div>
                                    </Carousel.Item>
                                )
                            })
                            // gallery.map((img) => {

                            //     return (
                            //         <img src={img} onClick={() => {
                            //             setImgSrc(img)
                            //         }} />
                            //     )
                            // })
                        }
                    </Carousel> */}
                    </div>
                </div>

            </section>
        </div>

    )
}

export default Gallery