import React from 'react'
import { Card } from 'react-bootstrap'

import { activities, otherActivities } from '../resources/activities'


function Activities() {
    return (
    <div className="paddingStyle">


        <section style={{ 'padding': '20px' }} className='paddingStyle'>
            <div className='activitiesContainer1'>
                {
                    activities.map(({ title, content, img, loc }) => {
                        return (
                            <div>


                                {
                                    <div>
                                        <div className='activities'>
                                            <div className='activitiesContent'>
                                                <div style={{textAlign:'center',padding:'20px 0px'}}>
                                                    <h2 style={{padding:'20px 0px'}}>{title}</h2>
                                                {img!==''?<img src={img} alt="" />:''}
                                                </div>
                                                <div>

                                                    <p>{content}
                                                        <p>Location: <i> {loc}</i></p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                       
                                    </div>
                                }
                            </div>
                        )
                    })
                }

                <div className='otherActivities'>
                    <h2>Other Activities:</h2>
                    <div>
                        <ul>

                            {otherActivities.map((actv) => {
                                return (
                                    <li>{actv}</li>
                                )
                            })}
                        </ul>
                    </div>

                </div>
            </div>
        </section>
                </div>
    )
}

export default Activities