import React from 'react'
import { Carousel } from 'react-bootstrap'

import { images } from '../images/images'

function Home() {
    return (
    <div >


        <section className='home' style={{ textAlign: 'center' }} >
            <Carousel variant="dark" className='paddingStyle'>
                {
                    images.map((img) => {
                        return (
                            <Carousel.Item>
                                <img src={img} className='carouselItem' alt="" />
                            </Carousel.Item>
                        )
                    })
                }

            </Carousel>

            <div className='missionVision'>
                <div>
                    <h3>Our Vision</h3>
                    <p  >To serve as catalyst for social, economic and moral transformation in the ailing urban and rural communities.</p>
                </div>
                <div>
                    <h3 >Our Mission</h3>
                    <p >
                        To serve individuals and families in the Urban and Rural communities to change their Social, Economic and Moral standard to the next level by creating awareness, thru guidance, providing opportunities and delivering relief in emergencies for a peaceful future.
                    </p>
                </div>
            </div>

        </section>
    </div>
    )
}

export default Home