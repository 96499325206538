import './App.scss';
import Nav1 from './components/Nav1';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Activities from './components/Activities';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import MakeDifferece from './components/MakeDifferece';
import Contact from './components/Contact';

import 'bootstrap/dist/css/bootstrap.css'
import Footer from './components/Footer';
import Involve from './components/Involve';
import BoardMembers from './components/BoardMembers';
import Founder from './components/Founder';
import { useState, useEffect } from 'react';
import App1 from './App1';
import Devlepment from './components/Devlepment';

function App() {
  const [isAdmin, setIsAdmin] = useState(false)
  const handleClick = () => {

    let admin = document.getElementById('admin').value
    let pass = document.getElementById('pass').value

    if (admin === 'cureAdmin' && pass === 'cure.in22') {
      document.getElementById('div').hidden=true
       setIsAdmin(true)
    }
    else {
       setIsAdmin(false)
    }
    console.log(isAdmin)
  }
  return (
    <section className='app'>
    <App1 /> 
    </section>
  );
}

export default App;
