import React from 'react'
import Nav1 from './components/Nav1';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Activities from './components/Activities';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import MakeDifferece from './components/MakeDifferece';
import Contact from './components/Contact';

import 'bootstrap/dist/css/bootstrap.css'
import Footer from './components/Footer';
import Involve from './components/Involve';
import BoardMembers from './components/BoardMembers';
import Founder from './components/Founder';

import phone from './images/contact/phone-solid.svg'
import email from './images/contact/envelope-solid.svg'
import{MdEmail,MdPhone} from "react-icons/md"


function App1() {
  return (
    <div >
      <div className='header'>
      <b>
        <MdPhone style={{fontSize:'18px',marginBottom:'3px'}}/>   <span>+91 8553355463</span>&nbsp;&nbsp;&nbsp;&nbsp;
      <MdEmail style={{fontSize:'18px',marginBottom:'3px'}}/>   <span>headoffice@cure-india.org</span>
      </b>
      </div >
      <Nav1 />

      <div className="mainBody">
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='about' element={<AboutUs />}></Route>
          <Route path='activities' element={<Activities />}></Route>
          <Route path='gallery' element={<Gallery />}></Route>
          <Route path='testimonials' element={<Testimonials />}></Route>
          <Route path='makeDifference' element={<MakeDifferece />}></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route path='involve' element={<Involve />}></Route>
          <Route path='bordMembers' element={<BoardMembers />}></Route>
          <Route path='founder' element={<Founder />}></Route>
        </Routes>

      </div>
      <Footer />
    </div >
  )
}

export default App1