import React from 'react'
import { individuals, corporates, corporates1 } from '../resources/activities'


function MakeDifferece() {

    return (
        <div className='paddingStyle'>
            <section className='makeDiffContainer'>
                <div>
                    <h2 className='toggleColor' >Get Involved</h2>
                    <p>
                        We accept volunteers from all walks of life and we are always looking for fresh ideas.
                        Volunteers can choose how to assist us based on our needs and their skills
                        GET INVOLVED....together we can make a difference
                    </p>
                </div>
                <div>
                    <h2 className='toggleColor' >individuals</h2>
                    <ul>

                        {individuals.map(({ skill, text }) => {
                            return (
                                <li><span style={{ textTransform: "capitalize" }}>{skill} </span> - {text}</li>
                            )
                        })}
                    </ul>
                </div>
                <div>
                    <h2 className='toggleColor' >corporates</h2>
                    <p>There is a large gap that needs to be bridged if we wish to empower the vulnerable and less privileged. The resources needed to achieve this are limitless and even a small drop in this vast ocean is welcome.
                        You can make a lump sum donation towards a specific purpose that your company would like to support:
                    </p>
                    <ul>

                        {corporates.map((cop) => {
                            return (
                                <li>{cop}</li>
                            )
                        })}
                    </ul>
                    <h4>
                        or help to meet recurring expenses:
                    </h4>
                    <ul>

                        {corporates1.map((cop) => {
                            return (
                                <li>{cop}</li>
                            )
                        })}
                    </ul>
                    <p>
                        <h3>
                            You can enable CURE to place donation boxes at your office premises or retail outlets to help your employees/customers contribute.
                        </h3>
                    </p>
                </div>
            </section>
        </div>
    )
}

export default MakeDifferece