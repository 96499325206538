// import img from '../images/MDS.jpg'
import president from '../images/MDS.jpg'


const bordMembers=[{img: president,name:'Mr. G. Mohan Doss',title:'President'},
{img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYU___dx8hmsm4i_BCg8flJoo2NKHo_Z0h1Q&usqp=CAU",name:'Mrs. Pandima Devi',title:'Member'},
{img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYU___dx8hmsm4i_BCg8flJoo2NKHo_Z0h1Q&usqp=CAU",name:'Dr. R. I. Jayarajan',title:'Member'},
{img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYU___dx8hmsm4i_BCg8flJoo2NKHo_Z0h1Q&usqp=CAU",name:'Mr. Rajkumar. V',title:'Member'},
{img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYU___dx8hmsm4i_BCg8flJoo2NKHo_Z0h1Q&usqp=CAU",name:'Mrs. N. D. Jasmine Beulah',title:'Member'},
]

export {bordMembers}