import gal1 from './gal1.jpg'
import gal2 from './gal2.jpg'
import gal3 from './gal3.jpg'
import gal4 from './gal4.jpg'
import gal5 from './gal5.jpg'
import gal6 from './gal6.JPG'
import gal7 from './gal7.JPG'
import gal8 from './gal8.JPG'
import gal9 from './gal9.JPG'
import gal10 from './gal10.JPG'
import gal11 from './gal11.jpg'
import gal12 from './gal12.jpg'
import gal13 from './gal13.jpg'
import gal14 from './gal14.jpg'
import gal15 from './gal15.jpg'
import gal16 from './gal16.jpg'
import gal17 from './gal17.jpg'
import gal18 from './gal18.jpg'
import gal19 from './gal19.jpg'
// import gal19 from './gal5.jpg'




const gallery = [gal1, gal2, gal3, gal4, gal5, gal6, gal7, gal8, gal9, gal10,gal11, gal12, gal13, gal14, gal15, gal16, gal18, gal19]

export { gallery } 